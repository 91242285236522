import React, {useContext, useState} from 'react'
import axios from 'axios'
import {Button, InputText, AuthContext} from '@upsellguru/usg-components-lib'
import {restUrls} from "../../../utils/constants";
import {usePostHog} from "posthog-js/react";

export default function TwoFactorAuthenticationChallengeForm() {
  const { setUserIsLoggedIn } = useContext(AuthContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [challengeCode, setChallengedCode] = useState('');
  const [serverErrorMessage, setServerErrorMessage] = useState('')
  const posthog = usePostHog()

  const handleSubmitForm = async () => {
    if (isSubmitting || challengeCode === '') {
      posthog.capture('sso_2fa_challenge_error', {
        error: 'PreValidation',
      });

      return false;
    }

    setIsSubmitting(true)
    const formData = new FormData();
    formData.append('code', challengeCode);
    try {
      await axios.post(restUrls.verifyTwoFaChallengeUrl, formData)
      setUserIsLoggedIn(true)
      posthog?.capture('sso_2fa_challenge_success')
      posthog?.capture('sso_login_success')
    } catch (error) {
      setServerErrorMessage(error.response.data.message)
      posthog?.capture('sso_2fa_challenge_error')
    }
    setIsSubmitting(false)

    return false;
  }

  return (
    <>
      <p className="two-fa-description error">
        <b>We have detected a different IP address from your last login.</b>
        <br />
        <br />
        To protect your account, we have blocked the login
        attempt. If this was an authorized login, please provide the temporary token we have sent an email to your
        account email address.
      </p>
      <div className="input-wrapper">
        <InputText
          onKeyPress={(e) => ((e.key === 'Enter' ? handleSubmitForm() : null))}
          label="Please enter the code received by email"
          id="code"
          name="code"
          placeholder=""
          value={challengeCode}
          onChange={e => {setChallengedCode(e.target.value)}}
        />
      </div>
      <div>
        <Button text="Submit" type="primary" onClick={handleSubmitForm} />
      </div>
      <div>
        <p className="error">{serverErrorMessage}</p>
      </div>
    </>
  )
}
