import React, {useEffect, useState} from 'react'
import {FullScreenLoading, AuthContext, LoadingContext} from '@upsellguru/usg-components-lib'
import Login from './pages/Login/Login'
import AppPicker from './pages/AppPicker/AppPicker'
import axios from "axios";
import {restUrls} from "./utils/constants";
import {changeWhitelabelTheme} from "./utils/changeWhitelabelTheme";
import {usePostHog} from "posthog-js/react";

function App() {
  const resellerId = usg.data.resellerId // eslint-disable-line
  const ssoTokenAudience = usg.data.ssoTokenAudience ?? null // eslint-disable-line
  const redirectTo = usg.data.redirectTo ?? null // eslint-disable-line
  const [isLoadingReseller, setIsLoadingReseller] = useState(resellerId > 0)
  const [resellerLogo, setResellerLogo] = useState(null)
  const [supportEmail, setSupportEmail] = useState(null)
  const [isInitialising, setIsInitialising] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(null)
  const [userData, setUserData] = useState({})
  const posthog = usePostHog()

  const loadReseller = async () => {
    if (!resellerId) {
      return;
    }

    setIsLoadingReseller(true)
    try {
      let response = await axios.get(`${restUrls.loadResellerWhitelabel}?resellerId=${resellerId}`)
      setIsLoadingReseller(false)
      setResellerLogo(response.data.logo)
      setSupportEmail(response.data.supportEmail)
      changeWhitelabelTheme(response.data.colors)
    } catch (error) {}
    setIsLoadingReseller(false)
  }

  useEffect(() => {
    setResellerLogo(null)
    loadReseller()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resellerId])

  const loadCurrentUser = async () => {
    if (userIsLoggedIn === false) {
      setIsLoading(false)

      return;
    }

    if (userData.hasOwnProperty('id') && userData.id) {
      return
    }

    setIsInitialising(true)
    setIsLoading(true)

    let response, currentUser = null
    try {
      response = await axios.get(restUrls.userInfo)
      if (response.status === 401){
        setUserIsLoggedIn(false)
      } else {
        setUserIsLoggedIn(true)
        currentUser = response.data
        setUserData(currentUser)
      }
      setIsLoading(false)
      setIsInitialising(false)
    } catch (error) {
      if (error.response.status === 401) {
        setIsInitialising(false)
      }
      setUserIsLoggedIn(false)
      setIsLoading(false)
    }

    if (currentUser) {
      posthog?.identify(
        currentUser.id,
        {
          email: currentUser.email,
          name: currentUser.name,
          division: currentUser.division,
          level: currentUser.level,
          department: currentUser.department,
        },
      );
    }
  }

  useEffect(() => {
    loadCurrentUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIsLoggedIn]);

  useEffect(() => {
    if (userData && ssoTokenAudience && userIsLoggedIn) {
      setIsLoading(true)
      let uri = '/sso/redirect?audience='+ssoTokenAudience
      if (redirectTo) {
        uri += '&'+redirectTo
      }
      window.location.href = uri
    }
  }, [ssoTokenAudience, userData, redirectTo, userIsLoggedIn]);

  return (
    <>
      <AuthContext.Provider value={{userIsLoggedIn, setUserIsLoggedIn, userData, setUserData}}>
        <LoadingContext.Provider value={{isLoading, setIsLoading}}>
          {(isLoading || isLoadingReseller) && <FullScreenLoading />}
          {!isInitialising && (userIsLoggedIn ? <AppPicker logo={resellerLogo}/> :
            <Login logo={resellerLogo} supportEmail={supportEmail}/>)}
        </LoadingContext.Provider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
