import React, {useContext, useEffect} from 'react'
import './AppPicker.css'
import {AuthContext, LoadingContext, UpsellGuruLogo} from '@upsellguru/usg-components-lib'
import {getRootStyle} from "../../utils/getRootStyle";
import ExtranetImage from '../../assets/images/ExtranetImage'
import GustaveImage from '../../assets/images/GustaveImage'
import HQImage from '../../assets/images/HQImage'

export default function AppPicker({logo}) {
  const { userData } = useContext(AuthContext)
  const { isLoading, setIsLoading } = useContext(LoadingContext)

  const redirectTo = (audience) => {
    setIsLoading(true)
    window.location.href = '/sso/redirect?audience='+audience
  }

  useEffect(() => {
    if (!userData) {
      return;
    }

    if (userData.allowedInternalSsoTokenAudience && userData.allowedInternalSsoTokenAudience.length === 2) {
      redirectTo(userData.allowedInternalSsoTokenAudience[1])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  if (isLoading || !userData) {
    return null;
  }

  let hasAccessToExtranet = false, hasAccessToGustave = false, hasAccessToHq = false
  for (let i in userData.allowedInternalSsoTokenAudience) {
    let audience = userData.allowedInternalSsoTokenAudience[i]
    switch (audience) {
      case 'EXTRANET':
        hasAccessToExtranet = true
        break;
      case 'GUSTAVE':
        hasAccessToGustave = true
        break;
      case 'HQ':
        hasAccessToHq = true
        break;
      default:
        break;
    }
  }

  return (
    <div className="login-page app-picker-section">
      <div className="app-picker-form">
        <div className="logo-section">
          {logo ?
            <span dangerouslySetInnerHTML={{__html: logo}} /> :
            <div className="usg-logo">
              <UpsellGuruLogo width={200}/>
            </div>
          }
        </div>
        <p className="app-picker-info">Pick an application to access</p>
        <div className="apps-selector">
          {hasAccessToExtranet &&
            <div className="app-button-wrapper" onClick={() => redirectTo('EXTRANET')}>
              <div className="app-button">
                <ExtranetImage width={65} color={getRootStyle('--primaryColor')}/>
              </div>
              <p>Extranet</p>
            </div>
          }
          {hasAccessToGustave &&
            <div className="app-button-wrapper" onClick={() => redirectTo('GUSTAVE')}>
              <div className="app-button">
                <GustaveImage width={65} color={getRootStyle('--primaryColor')}/>
              </div>
              <p>OnArrival</p>
            </div>
          }
          {hasAccessToHq &&
            <div className="app-button-wrapper" onClick={() => redirectTo('HQ')}>
              <div className="app-button">
                <HQImage width={65} color={getRootStyle('--primaryColor')}/>
              </div>
              <p>HQ</p>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
