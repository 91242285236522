import React, {useState, useContext} from 'react'
import {InputText, EmailIcon, Button, LockIcon, AuthContext, LoadingContext} from '@upsellguru/usg-components-lib'
import TwoFactorAuthenticationChallengeForm from './TwoFactorAuthenticationChallengeForm'
import {restUrls} from "../../../utils/constants";
import axios from "axios";
import {defaultSupportEmail} from "../../../utils/constants";
import {usePostHog} from "posthog-js/react";
import {getRootStyle} from "../../../utils/getRootStyle";

export default function LoginForm({supportEmail}) {
  const { setUserIsLoggedIn } = useContext(AuthContext)
  const { setIsLoading } = useContext(LoadingContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [userLoginData, setUserLoginData] = useState({email: '', password: ''})
  const [errorMessage, setErrorMessage] = useState({})
  const [serverErrorCode, setServerErrorCode] = useState('')
  const [serverErrorMessage, setServerErrorMessage] = useState('')
  const posthog = usePostHog()

  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setUserLoginData({...userLoginData, [name]: value})
  }

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.emailLogin = 'Email Address or Username is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }

    return errors;
  }

  const handleLoginSubmit = async () => {
    let errors = validate(userLoginData)
    setErrorMessage(errors)
    if (isSubmitting || Object.keys(errorMessage).length !== 0) {
      posthog.capture('sso_login_error', {
        error: 'PreValidation',
      });

      return false;
    }
    setIsSubmitting(true)
    setIsLoading(true)

    const userFormData = new FormData();
    userFormData.append('email', userLoginData.email);
    userFormData.append('password', userLoginData.password);

    try {
      await axios.post(restUrls.authUrl, userFormData)
      setUserIsLoggedIn(true)
      setIsLoading(false)
      posthog.capture('sso_login_success');
    } catch (error) {
      let errorCode = error.response.data.error
      setServerErrorCode(error.response.data.error)
      posthog.capture('sso_login_error', {
        error: errorCode,
      });

      let message = error.response.data.message
      if (errorCode === 'UserAuthenticationFailure') {
        message = 'There was an error while authenticating. Please check email address and/or password.';
      }
      if (errorCode === 'UserTemporarilyLocked' || errorCode === 'UserPermanentlyLocked') {
        message = error.response.data.errorMessage;
        if (supportEmail) {
          message = message.replace(defaultSupportEmail, supportEmail);
        }
      }
      if (errorCode === 'NoAccessibleHotels') {
        message = error.response.data.errorMessage;
      }
      setServerErrorMessage(message)
      setIsLoading(false)
    }
    setIsSubmitting(false)

    return false;
  }

  if (serverErrorCode === 'AuthenticationRequires2FA') {
    return <TwoFactorAuthenticationChallengeForm />;
  }

  return (
    <>
      <div className="input-wrapper">
        <InputText
          onKeyPress={(e) => ((e.key === 'Enter' ? handleLoginSubmit() : null))}
          labelIcon={<EmailIcon width="13px" color={getRootStyle('--primaryColor')} />}
          label="Email Address or Username"
          id="email"
          name="email"
          placeholder="Email Address or Username"
          value={userLoginData.email}
          onChange={e => handleLoginInputChange(e)}
        />
        <p className="error">{errorMessage.emailLogin}</p>
      </div>
      <div className="input-wrapper password-wrapper">
        <InputText
          onKeyPress={(e) => ((e.key === 'Enter' ? handleLoginSubmit() : null))}
          labelIcon={<LockIcon width="16px" color={getRootStyle('--primaryColor')}/>}
          label="Password"
          password={true}
          id="password"
          name="password"
          placeholder="Password"
          value={userLoginData.password}
          onChange={e => handleLoginInputChange(e)}
        />
        <p className="error">{errorMessage.password}</p>
      </div>
      <div>
        <Button text="Login" type="primary" onClick={handleLoginSubmit} />
      </div>
      <div>
        <p className="error">{serverErrorMessage}</p>
      </div>
    </>
  )
}
